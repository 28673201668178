<template>
  <div :class="`MessageChatCardUnlock`">
    <div class="MessagesPriceContent">
      <div v-if="!loading" class="MessageUnlockPrice" :title="$locale['credits']">
        <div class="MessageUnlockLabel" tcenter>
          {{ $locale["unlock_content_sure_by"] }}
        </div>
        <div class="MessageUnlockValue">
          <span class="MessagePriceNumber" strong>{{ Go.number_format(message.price) }}</span>
          <iconic class="MessagePriceIcon" name="coins"></iconic>
        </div>
        <div padding-bottom tcenter strong>{{ $locale["credits"] }}</div>
      </div>
      <Loader1A v-if="loading" class="UnlockLoader" />
      <div v-if="!loading" class="MessagePriceRight">
        <Confirm :confirm="confirm" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "confirmUnlock"],
  data: function() {
    return {
      loading: false,
    };
  },
  methods: {
    unlockMessageNow: async function() {
      this.loading = true;

      try {
        this.req = await this.api.post("message/unlock", { messageId: this.message._id });
        this.unlockMessageSuccess(this.req.data);
      } catch (error) {
        this.loading = false;
        return this.alert(Go.getErrorMessage(error));
      }

      this.loading = false;
    },
    unlockMessageSuccess: function(data = {}) {
      if (data.messageUnlocked) {
        this.$store.commit("updateMessage", { message: data.messageUnlocked });
      }

      if (data.reason === 1) {
        this.myCreditsCard();
      }

      if (!data.success) {
        return this.alert(data);
      }
    },
  },
  computed: {
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
          action: () => {
            this.confirmUnlock();
          },
        },
        accept: {
          label: this.$locale["unlock_content"],
          action: () => {
            this.unlockMessageNow();
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.MessageChatCardUnlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($alto, 0.8);
  padding: $mpadding * 2;
  display: flex;
  align-items: center;

  .MessagesPriceContent {
    position: relative;
    width: 100%;
    margin: auto;
  }

  .MessageUnlockValue {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
    padding: $mpadding $mpadding $mpadding/2 $mpadding;
    justify-content: center;
  }

  .MessagePriceNumber {
    font-size: 150%;
  }

  .MessageUnlockLabel {
    font-family: $third_font;
  }

  .UnlockLoader {
    position: absolute;
  }
}
</style>
